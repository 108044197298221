<template>
    <div class="row dashboard">
        <div class="col-12">
            <div class="row gy-3">
                <div class="col-md-12">
                    <h5 class="text-primary">Hi, {{activeUser.first_name}}</h5>
                </div>
                <div v-if="activeUser.id && !activeUser.is_verified" class="col-lg-12">
                    <user-email-verification />                   
                </div>
                <div class="col-md-4">
                    <div class="card text-center">
                        <div class="card-body position-relative">
                            <h5 class="text-primary"> <i class="ri ri-shield-star-line align-bottom"></i> Your Stuff</h5>
                            <p>{{result.items ? `${result.items > 1 ? `${result.items} items` : `${result.items} item`}` : 'no item'}} added to your plan</p>
                            <router-link class="text-decoration-underline" to="/items"> Add item </router-link>
                        </div>
                    </div>
                </div>
                 <div class="col-md-4">
                    <div class="card text-center">
                        <div class="card-body position-relative">
                            <h5 class="text-primary"> <i class="ri ri-file-text-line align-bottom"></i> Your Claims</h5>
                            <p> {{result.claims || 'no'}} currently open claims</p>
                            <router-link class="text-decoration-underline" to="/claims"> File a claim </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card text-center">
                        <div class="card-body position-relative">
                            <h5 class="text-primary"> <i class="ri  ri-account-circle-line align-bottom"></i> Your Account</h5>
                            <p>Information & setttings</p>
                            <router-link class="text-decoration-underline" to="/account"> Account settings </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import UserEmailVerification from "@/components/UserEmailVerification.vue"

export default {
    name: 'dashboard',
    components:{
        UserEmailVerification,
    },
    data(){
        return{
            result:{
                items: 0
            }
        }
    },
    computed:{
        activeUser(){
            return this.$store.state.AppActiveUser
        },
    },
    created(){
        this.$http.get('/analytics')
            .then((response) => {
                if(response.data.success){
                    this.result = response.data.data;
                }
            })
    }
}
</script>

<style scoped>
.user-photo{
    width:9rem;
}
@media(max-width:567px){
    .user-photo{
        width:4rem;
    }
}
.dashboard .table.profile-table td, .dashboard .table.profile-table th{
    padding:0.1rem 0.2rem;
}

</style>
